import React, { useEffect, useState } from "react"

export default function ScrollToTop() {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const trackScrolling = () => {
      if (typeof window !== `undefined`) {
        if (window.scrollY >= 200) {
          setShow(true)
        } else {
          setShow(false)
        }
      }

    }
    document.addEventListener("scroll", trackScrolling)
    return () => document.removeEventListener("scroll", trackScrolling)
  }, [])

  const toTop = () => {
    if (typeof window !== `undefined`) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    }

  }

  return (
    <div
      className="scroll-to-top"
      onClick={toTop}
      style={{ display: show ? "block" : "none" }}
    >
      <span className="scroll-icon">
        <i className="fas fa-arrow-up fa-sm" />
      </span>
    </div>
  )
}
