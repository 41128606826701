import React, { useEffect, useState } from "react"

const COOKIE_CONSENT_KEY = "cookieconsent_status"
const COOKIE_CONSENT_VALUE = "dismiss"

function createCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toGMTString()
  } else expires = ""

  document.cookie = name + "=" + value + expires + "; path=/"
}

function readCookie(name) {
  var nameEQ = name + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

// function eraseCookie(name) {
//   createCookie(name, "", -1)
// }

function CookieConsent() {
  const [show, setShow] = useState(true)
  useEffect(() => {
    const cookieConsentVal = readCookie(COOKIE_CONSENT_KEY)
    if (cookieConsentVal === COOKIE_CONSENT_VALUE) {
      setShow(false)
    }
  }, [])

  if (!show) return null
  return (
    <div
      className="cookieConsent"
      role="dialog"
      aria-live="polite"
      aria-label="cookieconsent"
      aria-describedby="cookieconsent:desc"
    >
      <span className="cookieConsent__message" id="cookieconsent:desc">
        Our site uses cookies to enhance your user experience and measure site
        traffic.
        <a
          className="cookieConsent__link"
          aria-label="learn more about cookies"
          role="button"
          tabIndex="0"
          href="privacy-policy.html"
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          Learn More
        </a>
      </span>
      <div className="cookieConsent__compliance">
        <a
          className="cmn-btn style--two"
          id="cookieConsentBtn"
          aria-label="dismiss cookie message"
          role="button"
          onClick={() => {
            createCookie(COOKIE_CONSENT_KEY, COOKIE_CONSENT_VALUE, 30)
            setShow(false)
          }}
        >
          Got it!
        </a>
      </div>
    </div>
  )
}

export default CookieConsent
