import React, { useEffect, useState } from "react"
import { animated, useSpring } from "react-spring"

function Preloader() {
  const [show, setShow] = useState(true)
  const [hide, setHide] = useState(false)

  const styles = useSpring({
    from: { opacity: 1 },
    to: { opacity: show ? 1 : 0 },
    delay: 0,
    onRest: () => {
      setHide(true)
    },
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false)
    }, 500)
    return () => clearTimeout(timer)
  }, [])

  if (hide) return null
  return (
    <animated.div className="preloader" style={{ ...styles }}>
      <div className="spinner">
        <div className="dot1"></div>
        <div className="dot2"></div>
      </div>
    </animated.div>
  )
}

export default Preloader
