import React from 'react'

function RecaptchaText() {
    const style={ color: "#1a4383", fontSize: "14px" };
    return (
        <div style={style}>
            <span className="mr-1" style={style}>
                This site is protected by reCAPTCHA and the Google{" "}
            </span>
            <a
                className="mr-1"
                href="https://policies.google.com/privacy"
            >
                Privacy Policy
                  </a>
            <span className="mr-1" style={style}>and</span>
            <a className="mr-1" href="https://policies.google.com/terms" >
                Terms of Service
                  </a>
            <span style={style}>apply.</span>
        </div>
    )
}

export default RecaptchaText
