import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { formPostHandler } from "../dal/apiRequest";

function NewsletterForm() {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Invalid email address')
                .required('Required'),
        }),
        onSubmit: async (values, actions) => {
            const token = await executeRecaptcha("submit");
            values.token = token;
            const success = await formPostHandler("newsletter-subscribers", values, "Thank your for your subscription.")

            if (success) {
                actions.resetForm({
                    values: {
                        email: '',
                    },
                })
            } else {
                actions.resetForm({
                    values: {
                        email: values.email,
                    },
                })
            }
            actions.setSubmitting(false);
        },
    });

    return (
        <form className="newsletter-form mb-3" onSubmit={formik.handleSubmit}>
            <div className="newsletter-form-control">
                <input
                    id="newsletterEmail"
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                <button
                    type="submit"
                >
                    Subscribe
                </button>
            </div>
            {formik.touched.email && formik.errors.email ? (
                <label className="error">{formik.errors.email}</label>
            ) : null}
        </form>
    );
}

export default NewsletterForm
