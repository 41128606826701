import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import NewsletterForm from "../forms/newsletterForm"
import RecaptchaText from "../components/Shared/recaptchaText"

const Footer = () => {
  return (
    <footer className="footer-section bg_img">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="newsletter-area">
              <div className="obj">
                <img src="/elements/subscribe.png"></img>
              </div>
              <div className="newsletter-content">
                <h2 className="title text-white">Find Out About New Games!</h2>
                <p className="text-white">
                  Subscribe to get updated on future game releases
                </p>
                <p />
                <NewsletterForm />
                <RecaptchaText />
              </div>
            </div>
          </div>
        </div>
        <div className="row footer-top mb-none-50">
          <div className="col-lg-6 col-sm-6 mb-50 d-flex justify-content-lg-center">
            <div className="footer-widget">
              <h3 className="footer-widget__title">SITE MAP</h3>
              <ul className="short-links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/games">Games</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/support">Support</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 col-sm-6 mb-50 d-flex justify-content-lg-center">
            <div className="footer-widget">
              <h3 className="footer-widget__title">LEGAL INFO</h3>
              <ul className="short-links">
                <li>
                  <Link to="/terms-of-service">Terms of Service</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/parents-guide">Parent's Guide</Link>
                </li>
                <li>
                  <Link to="/safe-fair-play-policy">
                    Safe and Fair Play Policy
                  </Link>
                </li>
                <li>
                  <a
                    href="/pdf/KVKK_Basvuru_Formu.docx"
                    target="_blank"
                    download={true}
                  >
                    KVKK Application Form
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row footer-bottom align-items-center">
          <div className="col-lg-7 col-md-6 text-md-left text-center">
            <p className="copy-right-text">
              Copyright © 2021.All Rights Reserved By &nbsp;
              <a href="/">NoSurrender Game Studio</a>
            </p>
          </div>
          <div className="col-lg-5 col-md-6 mt-md-0 mt-3">
            <ul className="social-links justify-content-md-end justify-content-center">
              <li>
                <a
                  href="https://www.youtube.com/channel/UC34NiHI78r3yCOZVJbmjc_g"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-youtube" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/nosurrender_studio"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/NoSurrender_HQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@nosurrender_hq"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-tiktok" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.snapchat.com/add/nosurrender_hq"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-snapchat-ghost" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/nosurrender"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
