const axios = require('axios').default;
// const ApiBaseUrl = "https://cms.nosurrender.studio";

export const formPostHandler = async (endpoint, data, successMessage) => {
    try {
        // let result = await axios.post(`${ApiBaseUrl}/${endpoint}`, data);
        alert(successMessage);
        return true;
    } catch (error) {
        alert(error.response.data.message)
        return false;
    }
}